<template>
     <div class="container-fluid py-4 add-student">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">  تعديل  موظف المبيعات</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                   <form @submit="updateSalesPerson" role="form" class="text-start mt-3">
                       <div class="row">
                <div class="mb-3 col-lg-6">
                    <label> الاسم<span class="red">*</span></label>
                  <vmd-input
                    id=""
                    type="text"
                   v-model='salesPersonN'
                  placeholder="الاسم "
                  required

                  />
                </div>
                </div>

                       <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    > حفظ</vmd-button
                  >
                </div>
                 <div class="text-center col-lg-6">
                    <router-link to="/sales">
                  <vmd-button
                  type='btn'
                    class="my-4 mb-2"
                    variant="gradient"
                    color="warning"
                    > رجوع</vmd-button
                  >
                  </router-link>
                </div>
                       </div>
              </form>
          </div>
        </div>
      </div>
    </div>
     </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import swal from 'sweetalert'

export default {
  name: 'EditSubject',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      salesPersonN: '',
      errors: []
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$http.get(`${this.$hostUrl}salespeople/${this.$route.params.id}`).then(response => {
      this.salesPersonN = response.data.data.name
    })
  },
  methods: {
    async updateSalesPerson (e) {
      e.preventDefault()
      const formData = new FormData()
      if (/^\d+$/.test(this.salesPersonN)) {
        swal('اسم الموظف يجب ان يحتوي على حروف')
        return 0
      }
      formData.append('name', this.salesPersonN)
      formData.append('_method', 'PUT')

      this.errors = await this.postRequest(formData, this.$hostUrl + 'salespeople/' + this.$route.params.id, '/sales')
    }
  }
}
</script>
